import { render, staticRenderFns } from "./ChangeDetails.vue?vue&type=template&id=7ba40c75&scoped=true&"
import script from "./ChangeDetails.vue?vue&type=script&lang=js&"
export * from "./ChangeDetails.vue?vue&type=script&lang=js&"
import style0 from "./ChangeDetails.vue?vue&type=style&index=0&id=7ba40c75&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ba40c75",
  null
  
)

export default component.exports