<template>
  <div class="el-dialog-div">
    <el-row>
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <div style=" font-size: 20px; color:#000;">
            变更前
          </div>
          <template v-for="(item, index) in oldList">
            <el-card
              style="margin-bottom:10px"
              :key="index"
              v-if="source == 0 || item.type == isType"
            >
              <div slot="header">
                <template v-if="rulesNum == 1">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'chan_pin_xiao_shou_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>

                <template v-if="rulesNum == 3">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'engineering_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'software_designing_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'planning_approval_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'make_maps_data_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>

                <template v-if="rulesNum == 2">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="
                      item.type ==
                        'you_gong_si_shang_wu_tuan_dui_huo_yuan_gong_zhi_jie_huo_qu_de_xiang_mu'
                    "
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'yi_wai_bu_he_zuo_zhe_wei_zhu_huo_qu_de_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'di_san_fang_gong_si_gua_kao_hui_tu_de_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>
              </div>
              <template v-for="a in item.ruleList">
                <div class="expense" :key="a.id">
                  <span>{{ a.name }}</span> =
                  <template v-for="b in a.ruleDataList">
                    <span :key="b.id">
                      <span v-if="b.ruleType == 'FIELD'">{{ b.name }}</span>
                      <span v-if="b.ruleType == 'OPERATOR'" class="operator">{{ b.code }}</span>
                      <el-input
                        disabled
                        v-if="b.ruleType == 'PER_CENT'"
                        :class="
                          b.isAlreadyChange == 1
                            ? 'rulesInput color bd_input'
                            : 'rulesInput bd_input'
                        "
                        style="width:100px"
                        v-model.number="b.code"
                      ></el-input>
                      <template v-if="b.ruleGroupList.length > 0">
                        (
                        <template v-for="c in b.ruleGroupList">
                          <span :key="c.id">
                            <span v-if="c.ruleType == 'FIELD'">{{ c.name }}</span>
                            <el-input
                              disabled
                              v-if="c.ruleType == 'VALUE' || c.ruleType == 'PER_CENT'"
                              style="width:100px"
                              :class="[
                                c.isAlreadyChange == 1 ? 'color bd_input' : 'bd_input',
                                c.ruleType == 'PER_CENT' ? 'rulesInput' : '',
                              ]"
                              v-model.number="c.code"
                            ></el-input>
                            <span v-if="c.ruleType == 'OPERATOR'" class="operator">{{
                              c.code
                            }}</span>
                            <template v-if="c.ruleGroupChildList.length > 0">
                              <template v-for="d in c.ruleGroupChildList">
                                <span :key="d.id">
                                  <span v-if="d.ruleType == 'FIELD'">{{ d.name }}</span>
                                  <span v-if="d.ruleType == 'OPERATOR'" class="operator">{{
                                    d.code
                                  }}</span>
                                </span>
                              </template>
                            </template>
                          </span> </template
                        >)
                      </template>
                    </span>
                  </template>
                </div>
              </template>
            </el-card>
          </template>
        </div></el-col
      >

      <el-col :span="12">
        <div class="grid-content bg-purple-light">
          <div style=" font-size: 20px; color: rgb(245, 62, 62);">
            变更后
          </div>

          <template v-for="(item, index) in newList">
            <el-card
              style="margin-bottom:10px"
              :key="index"
              v-if="source == 0 || item.type == isType"
            >
              <div slot="header">
                <template v-if="rulesNum == 1">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'chan_pin_xiao_shou_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>

                <template v-if="rulesNum == 3">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'engineering_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'software_designing_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'planning_approval_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'make_maps_data_professional_work'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>

                <template v-if="rulesNum == 2">
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="
                      item.type ==
                        'you_gong_si_shang_wu_tuan_dui_huo_yuan_gong_zhi_jie_huo_qu_de_xiang_mu'
                    "
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'yi_wai_bu_he_zuo_zhe_wei_zhu_huo_qu_de_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                  <span
                    style="font-weight: 900; color: black; font-size: 18px;"
                    v-if="item.type == 'di_san_fang_gong_si_gua_kao_hui_tu_de_xiang_mu'"
                    >{{ item.type | dict(rulesType, "dictVal", "dictName") }}</span
                  >
                </template>
              </div>
              <template v-for="a in item.ruleList">
                <div class="expense" :key="a.id">
                  <span>{{ a.name }}</span> =
                  <template v-for="b in a.ruleDataList">
                    <span :key="b.id">
                      <span v-if="b.ruleType == 'FIELD'">{{ b.name }}</span>
                      <span v-if="b.ruleType == 'OPERATOR'" class="operator">{{ b.code }}</span>
                      <el-input
                        disabled
                        v-if="b.ruleType == 'PER_CENT'"
                        :class="
                          b.isAlreadyChange == 1
                            ? 'rulesInput color bd_input'
                            : 'rulesInput bd_input'
                        "
                        style="width:100px"
                        v-model.number="b.code"
                      ></el-input>
                      <template v-if="b.ruleGroupList.length > 0">
                        (
                        <template v-for="c in b.ruleGroupList">
                          <span :key="c.id">
                            <span v-if="c.ruleType == 'FIELD'">{{ c.name }}</span>
                            <el-input
                              disabled
                              v-if="c.ruleType == 'VALUE' || c.ruleType == 'PER_CENT'"
                              style="width:100px"
                              :class="[
                                c.isAlreadyChange == 1 ? 'color bd_input' : 'bd_input',
                                c.ruleType == 'PER_CENT' ? 'rulesInput' : '',
                              ]"
                              v-model.number="c.code"
                            ></el-input>
                            <span v-if="c.ruleType == 'OPERATOR'" class="operator">{{
                              c.code
                            }}</span>
                            <template v-if="c.ruleGroupChildList.length > 0">
                              <template v-for="d in c.ruleGroupChildList">
                                <span :key="d.id">
                                  <span v-if="d.ruleType == 'FIELD'">{{ d.name }}</span>
                                  <span v-if="d.ruleType == 'OPERATOR'" class="operator">{{
                                    d.code
                                  }}</span>
                                </span>
                              </template>
                            </template>
                          </span> </template
                        >)
                      </template>
                    </span>
                  </template>
                </div>
              </template>
            </el-card>
          </template>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
export default {
  name: "ChangeDetails",
  components: {},
  props: {
    isType: {
      type: String,
      default: "",
    },
    source: {
      type: Number,
      default: 0,
    },
    rulesNum: {
      type: Number,
    },
    rulesType: {
      type: Array,
      default: function() {
        return [];
      },
    },
    list: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      newList: [],
      oldList: [],
    };
  },
  computed: {},
  watch: {
    list: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function(newVal, oldVal) {
        newVal.forEach((v) => {
          if (v.logType == "OLD") {
            this.oldList = v.difRuleList;
          } else if (v.logType == "CHANGE") {
            this.newList = v.difRuleList;
          }
        });
      },
    },
  },
  created() {},
  beforeDestroy() {
    this.newList = [];
    this.oldList = [];
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
.el-dialog-div {
  height: 70vh;
  overflow: auto;
  .expense {
    font-size: 16px;
    padding-bottom: 8px;
    margin-bottom: 10px;
    height: 50px;
    width: 100%;
    display: inline-block;
    line-height: 50px;
    .operator {
      margin: 0 8px;
    }
    .rulesInput::after {
      content: "%";
      position: absolute;
      right: 8%;
      bottom: -12%;
    }
    .color {
      background-color: #f5e9057d !important;
    }
    .bd_input {
      border: 1px solid #ccc;
      height: 45px;
      border-radius: 10%;
      text-align: center;
    }
    // 改变input框背景颜色
    /deep/.el-input__inner {
      background-color: transparent !important;
      border: 0 !important;
    }
    /* 利用穿透，设置input边框隐藏 */
    .bd_input >>> .el-input__inner {
      border: 0;
    }
  }
}
</style>
